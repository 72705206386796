import React from 'react';
import PropTypes from 'prop-types';
//import { Gmaps } from 'react-gmaps';
import styles from './styles.module.css';
// import CSSModules from 'react-css-modules';

class GMAP extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
      zoom: this.props.zoom,

    };

  }

  componentDidMount() {

    // create map
    //const params = { v: '3.exp', key: `${process.env.GOOGLE_MAPS_API_KEY}` };


    //const mapType = this.state.section.data[2].mapType ? 'satellite' : 'roadmap';
    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_MAPS_API_KEY}
    &q=${this.state.section.data[2].address}&zoom=${this.state.zoomLevel}&maptype="roadmap"`;

    /*
    this.map = (<Gmaps
      lat={this.state.lat}
      lng={this.state.lng}
      zoom={this.state.zoom}
      loadingMessage=""
      params={params}
      style={{ height: '100%' }}
      onMapCreated={this.initMap}
    />);
    */

    // eslint-disable-next-line jsx-a11y/iframe-has-title
    this.map = (<iframe
      frameBorder="0"
      className={styles.iframeStyle}
      src={mapUrl}
      allowFullScreen
    />);

  }

  // eslint-disable-next-line class-methods-use-this
  render() {

    return <div id="map">Loading</div>;

  }

}


GMAP.propTypes = {

  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,

};

export default GMAP;
